@import url(https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700;800;900&display=swap);
html,
body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:link,a:visited,a:active {
  color: inherit;
  text-decoration: underline;
}

